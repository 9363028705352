import React, { FC } from 'react';
import { MediaBannerTypes } from '@shared/types/umbraco';
import classNames from 'classnames';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { graphql } from 'gatsby';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import Video from 'common/Video';
import Section from 'common/Section';
import './MediaBanner.scss';

const MediaBanner: FC<MediaBannerTypes.IMediaBannerProps> = ({
  color,
  description,
  title,
  imageWithDescription,
  link,
  imageAutoHeight,
  alignImage = ['left'],
  fileLink,
}) => {
  const { image, imageDescription, youtubeLink, vimeoLink } =
    imageWithDescription?.[0]?.properties || {};

  return (
    <Section
      name="media-banner"
      color={color}
      intro={title}
      classes={classNames({
        'media-banner-section--reverse': alignImage?.[0] === 'left',
        'media-banner-section--top': alignImage?.[0] === 'top',
        'media-banner-section--bottom': alignImage?.[0] === 'bottom',
        'media-banner-section--image-auto': imageAutoHeight,
      })}
    >
      <div className="media-banner-section__main">
        {image ? (
          <figure className="media-banner-section__media-container">
            <GatsbyImage
              image={image[0].properties.image}
              alt={image[0].properties.imageAlt}
              className="image-container__image"
            />
            {imageDescription ? (
              <figcaption>
                <DangerouslySetInnerHtml
                  element="figcaption"
                  className="image-container__description"
                  html={imageDescription}
                />
              </figcaption>
            ) : null}
          </figure>
        ) : null}
        {youtubeLink || vimeoLink ? <Video {...imageWithDescription?.[0]?.properties} /> : null}
        <div className="media-banner-section__info-container">
          {description && (
            <DangerouslySetInnerHtml className="info-container__text" html={description} />
          )}
          {link?.length > 0 && (
            <Button
              classes="media-banner-section__button"
              to={link?.[0]?.properties?.link?.[0]?.url}
              ariaLabel={link[0].properties.ariaLabel}
              target={link[0].properties.link[0]?.target}
              variant="secondary-link"
              iconSuffix="chevron-right"
            >
              {link[0].properties.link[0]?.name}
            </Button>
          )}
          {!!fileLink?.length && (
            <Button
              classes="media-banner-section__button"
              to={fileLink[0]?.properties?.link?.url}
              ariaLabel={fileLink[0]?.properties?.ariaLabel}
              target="_blank"
              variant="secondary-link"
              iconSuffix="chevron-right"
            >
              {fileLink[0]?.properties?.name}
            </Button>
          )}
        </div>
      </div>
    </Section>
  );
};

export const query = graphql`
  fragment FragmentMediaBanner on TNewMediaBanner {
    properties {
      title
      color {
        label
        value
      }
      description
      imageWithDescription {
        properties {
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 900, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          previewImage {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageAlt
            }
          }
          imageDescription
          youtubeLink
          vimeoLink
          btnAriaLabel
          time
          description
        }
        structure
      }
      link {
        properties {
          link {
            name
            url
            target
          }
          ariaLabel
        }
      }
      alignImage
      showInMenu
      anchorName
      anchorTitle
      imageAutoHeight
      fileLink {
        properties {
          link {
            url
          }
          name
          ariaLabel
        }
      }
    }
    structure
  }
`;

export default MediaBanner;

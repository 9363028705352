import React, { FC } from 'react';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import { IQuizOption } from 'components/Quiz/models';
import IconCustom from 'common/IconCustom';
import './QuizOption.scss';

const QuizOption: FC<IQuizOption> = ({
  title,
  description,
  icon,
  nextSlide,
  tags,
  name,
  handleChange,
  handleKeyPress,
  value,
  handleClick,
}) => (
  <>
    <input
      className="sr-only quiz-option__input"
      type="radio"
      id={title}
      name={name}
      value={title}
      checked={value === title}
      onChange={handleChange}
      onKeyPress={(e) => handleKeyPress(e, tags, nextSlide)}
    />
    <label
      className="quiz-option-label btn btn--icon"
      data-testid="quiz-option-label"
      htmlFor={title}
      onMouseDown={(e) => handleClick(e, tags, nextSlide)}
    >
      {icon?.length > 0 && <IconCustom icon={icon[0]} />}
      {title && <DangerouslySetInnerHtml className="quiz-option-label__title" html={title} />}
      {description && (
        <DangerouslySetInnerHtml className="quiz-option-label__description" html={description} />
      )}
    </label>
  </>
);

export default QuizOption;
